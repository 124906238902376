<template>
  <div id="api-directory" class="position-relative">
    <div class="bg-light">
      <div class="container space-2">
        <div class="row align-items-center">
          <div class="col-12 col-md-6 mb-6 mb-md-0">
            <h2 class="mb-3">{{ $t('OW01HomePage.ApiDirectorySection.Title') }}</h2>
            <p class="mb-4">{{ $t('OW01HomePage.ApiDirectorySection.Description') }}</p>
            <div class="input-group w-lg-80">
              <input v-model="searchQuery" :placeholder="$t('OW01HomePage.HeroSection.SearchAPI')" type="text" class="form-control" required @keypress.enter="search">
              <div class="input-group-append">
                <button class="btn btn-block btn-primary" @click="search"><i class="far fa-search mr-1" /> {{ $t('OW01HomePage.HeroSection.Search') }}</button>
              </div>
            </div>
            <div class="mt-4">
              <a class="font-weight-bold text-primary" href="https://hub.openapihub.com/api-catalogue/all" target="_blank">{{ $t('OW01HomePage.HeroSection.Explore') }} <i class="far fa-arrow-right ml-1" /></a>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/api-consultancy.svg" alt="API Directory">
            </div>
          </div>
        </div>

        <!-- <div class="space-top-2">
          <div class="w-md-80 w-lg-80 text-center mx-md-auto mb-5">
            <h2>{{ $t('OW01HomePage.ApiDirectorySection.CategoryTitle') }}</h2>
          </div>

          <Slick ref="useCasesCarousel" :options="slickOptions" class="use-cases-carousel">
            <div v-for="(category, index) in categories" :key="index" class="pt-2 use-cases-card">
              <router-link
                class="card bg-img-hero w-100 min-h-270rem transition-3d-hover"
                :to="{ path: `/${$route.params.lang}/use-cases/${category.id}`}"
                :style="{ background: `url(${require(`@/assets/svg/illustrations/use-cases/${category.bgImage}.svg`)}) center no-repeat`, backgroundSize: 'auto 100%', backgroundColor: `${category.bgColor}`}"
              >
                <div class="card-body text-shadow">
                  <small class="d-block small text-white font-weight-bold text-cap mb-2">{{ $t('OW01HomePage.UseCasesSection.Category') }}</small>
                  <h3 class="text-white">{{ $t(category.categoryName) }}</h3>
                </div>
              </router-link>
            </div>
          </Slick>

          <div class="space-top-2 text-center">
            <a href="https://hub.openapihub.com/en-us/categories/" class="btn btn-primary" target="_blank">{{ $t('OW01HomePage.ApiDirectorySection.CategoryButton') }}</a>
          </div>

        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Slick from 'vue-slick'
import UseCasesCategories from '../../use-cases/useCasesCategories.json'

export default {
  name: 'ApiDirectory',
  components: {
    // Slick
  },
  data() {
    return {
      searchQuery: '',
      categories: UseCasesCategories.Categories,
      slickOptions: {
        slidesToShow: 4,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        prevArrow: '<span class="fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-n3" />',
        nextArrow: '<span class="fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-n3" />',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 554,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    }
  },
  beforeUpdate() {
    if (this.$refs.useCasesCarousel) {
      this.$refs.useCasesCarousel.destroy()
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.useCasesCarousel) {
        this.$refs.useCasesCarousel.create(this.slickOptions)
      }
    })
  },
  methods: {
    search() {
      const encodedQuery = encodeURIComponent(this.searchQuery)
      const url = `https://hub.openapihub.com/api-catalogue/all?key=${encodedQuery}`
      window.open(url, '_blank')
      this.clearInput()
    },
    clearInput() {
      this.searchQuery = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.use-cases-card {
  padding: 0px 5px;
}
</style>
