<template>
  <div id="connect-api" class="position-relative">
    <div class="bg-light">
      <div class="container space-2">
        <div class="row align-items-center">
          <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
            <h2 class="mb-3">{{ $t('OW01HomePage.ConnectApiSection.Title') }}</h2>
            <p>{{ $t('OW01HomePage.ConnectApiSection.Description') }}</p>
            <div class="mb-3">
              <router-link
                :to="{ path: `/${$route.params.lang}/api-provider`}"
                class="btn btn-primary btn-wide"
              >{{ $t('OW01HomePage.ConnectApiSection.Button1') }}</router-link>
            </div>
            <a href="https://blog.openapihub.com/en-us/list-api-on-openapihub/" class="btn btn-outline-primary btn-white" target="_blank">{{ $t('OW01HomePage.ConnectApiSection.Button2') }}</a>
          </div>
          <div class="col-12 col-md-6 order-md-1">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/api-world.svg" alt="Define API Access Rights">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConnectAPI'
}
</script>

<style lang="scss" scoped>
.use-cases-card {
  padding: 0px 5px;
}
</style>
