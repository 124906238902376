<template>
  <div>
    <div id="ctaSection" class="container space-2">
      <div class="card border-0 shadow space-2" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-hero-1.svg`)})`}">
        <div class="card-body text-center py-5">
          <h2>{{ $t('OW01HomePage.OahCtaSection.Title') }}</h2>
          <p class="mb-4 lead">{{ $t('OW01HomePage.OahCtaSection.Description') }}</p>
          <a class="btn btn-primary" href="https://hub.openapihub.com/login" target="_blank">{{ $t('OW01HomePage.OahCtaSection.Button') }}</a>
        </div>
      </div>
    </div>

    <!-- <div id="ctaSection" class="container space-2" :style="{ background: `url(${require(`@/assets/svg/components/abstract-shapes-19.svg`)}) center no-repeat`}">
      <div class="text-center py-6">
        <h2>{{ $t('OW01HomePage.OahCtaSection.Title') }}</h2>
        <p class="lead">{{ $t('OW01HomePage.OahCtaSection.Description') }}</p>
        <span class="d-block mt-5">
          <a class="btn btn-primary" href="https://hub.openapihub.com/login" target="_blank">{{ $t('OW01HomePage.OahCtaSection.Button') }}</a>
        </span>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'BnCTA'
}
</script>

<style lang="scss" scoped>

</style>
