<template>
  <div id="hub" class="position-relative">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
        <h2>{{ $t('OW01HomePage.OahResourcesSection.Title') }}</h2>
        <p>{{ $t('OW01HomePage.OahResourcesSection.Description') }}</p>
      </div>

      <div class="row justify-content-center">
        <div v-for="(card, index) in registerCards" :key="index" class="col-12 col-md-6 mb-4">
          <a :href="`${card.link}`" class="card card-bg-light h-100 shadow-soft overflow-hidden" target="_blank">
            <div class="row align-items-center no-gutters">
              <div class="col-8 col-md-12 col-lg-7">
                <div class="py-3 pl-4">
                  <h4 class="d-none d-lg-block">{{ $t(card.title) }}</h4>
                  <h5 class="d-lg-none">{{ $t(card.title) }}</h5>
                  <p class="font-size-1">{{ $t(card.description) }}</p>
                  <a v-if="card.link" :id="`${card.id}`" :href="`${card.link}`" class="font-size-1 font-weight-bold" target="_blank">{{ $t(card.button) }} <i class="fas fa-angle-right fa-sm ml-1" /></a>
                  <!-- <router-link v-if="card.router_link" :id="`${card.id}`" :to="{ path: `/${$route.params.lang}/${card.router_link}`}" class="font-size-1 font-weight-bold">{{ $t(card.button) }} <i class="fas fa-angle-right fa-sm ml-1" /></router-link> -->
                  <a v-if="card.form" :id="`${card.id}`" href="javascript:;" data-toggle="modal" :data-target="`#${card.form}`" class="font-size-1 font-weight-bold">{{ $t(card.button) }} <i class="fas fa-angle-right fa-sm ml-1" /></a>
                </div>
              </div>
              <div class="col-4 col-md-12 col-lg-5 px-0">
                <img class="img-fluid" :src="require(`@/assets/svg/illustrations/${card.img}.svg`)">
              </div>
            </div>
          </a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'OahResources',
  data() {
    return {
      registerCards: [
        {
          title: 'OW01HomePage.OahResourcesSection.Card1Title',
          description: 'OW01HomePage.OahResourcesSection.Card1Description',
          img: 'academy-bulb',
          button: 'OW01HomePage.OahResourcesSection.Card1Button',
          link: 'https://www.openapihub.com/academy'
        },
        {
          title: 'OW01HomePage.OahResourcesSection.Card2Title',
          description: 'OW01HomePage.OahResourcesSection.Card2Description',
          img: 'api-blog',
          button: 'OW01HomePage.OahResourcesSection.Card2Button',
          link: 'https://blog.openapihub.com/'
        },
        {
          title: 'OW01HomePage.OahResourcesSection.Card4Title',
          description: 'OW01HomePage.OahResourcesSection.Card4Description',
          img: 'community-apply',
          button: 'OW01HomePage.OahResourcesSection.Card4Button',
          link: 'https://www.youtube.com/@openapihub'
        },
        {
          title: 'OW01HomePage.OahResourcesSection.Card3Title',
          description: 'OW01HomePage.OahResourcesSection.Card3Description',
          img: 'events-home',
          button: 'OW01HomePage.OahResourcesSection.Card3Button',
          link: 'https://www.openapihub.com/en-us/community-updates'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
