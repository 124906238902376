<template>
  <div>
    <Hero />
    <ApiDirectory />
    <OahResources />
    <ConnectAPI />
    <OahCTA />

    <!-- Open API Academy -->
    <!-- <div class="bg-light">
      <div class="container space-2">
        <div>

          <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5 mb-md-7">
            <h2>The Home of API Learning</h2>
            <p>Learn how to start your first API journey with OpenAPIHub.</p>
          </div>

          <div class="row">
            <div class="col-lg-6 mb-3 mb-lg-0">
              <article class="card h-100">
                <div class="w-sm-65 p-4">
                  <h3>API Videos</h3>
                  <div class="mb-4">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, corrupti!</p>
                  </div>
                  <a class="btn btn-sm btn-soft-primary transition-3d-hover" href="#">Find out More <i class="fas fa-angle-right ml-1" /></a>
                </div>

                <div class="position-absolute bottom-0 right-0 w-sm-35 max-w-27rem">
                  <img class="img-fluid" src="@/assets/svg/illustrations/consumer-apply.svg" alt="Image Description">
                </div>
              </article>
            </div>

            <div class="col-lg-6">
              <article class="card h-100">
                <div class="w-sm-65 p-4">
                  <h3>Featured Articles</h3>
                  <div class="mb-4">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                  </div>
                  <a class="btn btn-sm btn-soft-primary transition-3d-hover" href="#">Find out More <i class="fas fa-angle-right ml-1" /></a>
                </div>

                <div class="position-absolute bottom-0 right-0 w-sm-35 max-w-27rem">
                  <img class="img-fluid" src="@/assets/svg/illustrations/community-apply.svg" alt="Image Description">
                </div>
              </article>
            </div>
          </div>

          <div class="space-top-2 text-center">
            <a href="#" class="btn btn-primary">Visit Open API Academy</a>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Events -->
    <!-- <div class="container space-2">
      <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5 mb-md-7">
        <h2>Community Events</h2>
        <p>Discover the most recent events that have taken place in our community</p>
      </div>

      <div class="row mx-auto mb-5">
        <div class="col-md-4 mb-4 mb-md-0">
          <a class="card h-100 transition-3d-hover" href="https://unwire.pro/2023/09/04/benovelty-2/opendata/" target="_blank">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/apidays-hongkong-2023.jpg">
              <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                  <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                </svg>
              </figure>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <p class="font-size-1">CONFERENCE</p>
                <h4>Apidays Hong Kong 2023</h4>
              </div>
            </div>
            <div class="card-footer border-0 pt-0">
              <p class="small">August 30-31, 2023</p>
            </div>
          </a>
        </div>
        <div class="col-md-4 mb-4 mb-md-0">
          <a class="card h-100 transition-3d-hover" href="https://itpromag.com/2023/08/31/hkstp-apidays-2023/" target="_blank">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/apidays-live-hongkong-2022.jpg">
              <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                  <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                </svg>
              </figure>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <p class="font-size-1">CONFERENCE</p>
                <h4>Apidays Hong Kong 2022</h4>
              </div>
            </div>
            <div class="card-footer border-0 pt-0">
              <p class="small">Aug 24-25, 2022</p>
            </div>
          </a>
        </div>
        <div class="col-md-4 mb-0">
          <a class="card h-100 transition-3d-hover" href="https://www.hkstp.org/news-room/hkstp-and-benovelty-host-apidays-connect-hong-kong-2023-to-boost-digital-economy-development/" target="_blank">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/apidays-live-hongkong-2021.jpg">
              <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                  <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                </svg>
              </figure>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <p class="font-size-1">CONFERENCE</p>
                <h4>Apidays Live Hong Kong 2021</h4>
              </div>
            </div>
            <div class="card-footer border-0 pt-0">
              <p class="small">August 25-26, 2021</p>
            </div>
          </a>
        </div>
      </div>

      <div class="col-12 text-center">
        <router-link class="btn btn-primary transition-3d-hover mt-3 mt-md-5" :to="{ path: '/press' }">View More Events</router-link>
      </div>

      <div class="w-lg-75 mx-lg-auto space-2">
        <div class="card overflow-hidden p-5">
          <div class="row justify-content-md-start align-items-md-center text-center text-md-left z-index-2">
            <div class="col-md-8 col-lg-9 mb-3 mb-md-0 pl-lg-5">
              <h3 class="mb-0">Interested in sponsoring our events?</h3>
            </div>
            <div class="col-md-4 col-lg-3 text-md-right">
              <router-link class="btn btn-primary" :to="{ path: `/${$route.params.lang}/academy`}">Learn How</router-link>
            </div>
          </div>
          <figure class="w-35 position-absolute top-0 left-0 mt-n11 ml-n11">
            <img class="img-fluid" src="@/assets/svg/components/half-circle-2-light.svg">
          </figure>
        </div>
      </div>

    </div> -->

    <!-- <OahOverview class="space-2" />
    <Hub />
    <Roles />
    <Pricing />
    <CTA />
    <HeroBanner />
    <Register />
    <About />
    <KeyFeatures />
    <GeneratorCTA />
    <UseCases />
    <BnCTA />
    <MediaCoverage />
    <Newsletter /> -->
  </div>
</template>

<script>
import Hero from './components/hero'
import ApiDirectory from './components/api-directory'
import OahResources from './components/oah-resources'
import ConnectAPI from './components/connect-api'
import OahCTA from './components/oah-cta'

// import OahOverview from '@/components/oah-overview'
// import Hub from './components/hub'
// import Roles from './components/roles'
// import Pricing from './components/pricing'
// import CTA from './components/cta'

// import HeroBanner from './components/hero-banner'
// import About from './components/about'
// import KeyFeatures from './components/key-features'
// import GeneratorCTA from './components/generator-cta'
// import UseCases from './components/use-cases'
// import Register from '@/components/register'
// import BnCTA from './components/bn-cta'
// import MediaCoverage from './components/media-coverage'
// import Newsletter from './components/newsletter'

export default {
  name: 'Home',
  components: {
    Hero,
    ApiDirectory,
    OahResources,
    ConnectAPI,
    OahCTA

    // UseCases,
    // OahOverview,
    // Hub,
    // Roles,
    // Pricing,
    // CTA,
    // HeroBanner,
    // About,
    // KeyFeatures,
    // GeneratorCTA,
    // Register,
    // BnCTA,
    // MediaCoverage,
    // Newsletter
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: '%s | API Business Platform & Hub Community',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/' }
      ],
      meta: [
        { name: 'description', content: 'Cloud based API Business Platform & Hub Community to bootstrap modern API businesses.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'OpenAPIHub | API Business Platform & Hub Community' },
        { property: 'og:description', content: 'Cloud based API Business Platform & Hub Community to bootstrap modern API businesses.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.media-coverage-carousel {
  width: 100%;
}
</style>
