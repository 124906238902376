<template>
  <div class="position-relative bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-industry-hero.svg`)})`}">
    <!-- <div class="container space-top-1 space-top-lg-2 position-relative">
      <div class="row justify-content-lg-between align-items-center">
        <div class="col-lg-7 col-xl-6 text-center text-lg-left">
          <div class="mb-5 mb-lg-0">
            <div class="text-body">
              <h1 class="font-size-5 mb-0 d-inline-block">
                <span v-html="$t('OW01HomePage.HeroSection.Title')" />
                <span class="text-primary font-weight-bold d-inline-block">
                  <vue-typed-js :key="$i18n.locale" :strings="typedKeywords" :loop="true" :typeSpeed="60" :backSpeed="25" :backDelay="1500" :smartBackspace="true">
                    <span class="typing" />
                  </vue-typed-js>
                </span>
              </h1>
            </div>
            <p class="lead mb-5">{{ $t('OW01HomePage.HeroSection.Description') }}</p>
            <a id="" href="https://hub.openapihub.com/" class="btn btn-primary btn-wide mr-2 mr-sm-3 mb-2" target="_blank">{{ $t('OW01HomePage.HeroSection.VisitOAH') }}</a>
            <router-link :to="{ path: `/${$route.params.lang}/academy`}" class="btn btn-outline-primary btn-white btn-wide mr-2 mr-sm-0 mb-2">{{ $t('OW01HomePage.HeroSection.Academy') }}</router-link>
          </div>
        </div>

        <div class="col-lg-5 col-xl-6">
          <div data-gtm-att="ga-intro-video-card" class="card shadow-none overflow-hidden mx-lg-auto bg-transparent">
            <a href="javascript:;" data-toggle="modal" data-target="#videoModal" class="video-player">
              <img class="img-fluid video-player-preview transition-3d-hover" src="@/assets/svg/illustrations/home-hero.svg">
              <div class="video-player-btn video-player-centered">
                <span class="video-player-icon video-player-icon-pill">
                  <i class="fas fa-play-circle mr-2 font-size-2" /> {{ $t('OW01HomePage.HeroSection.WatchButton') }}
                </span>
              </div>
              <div class="embed-responsive embed-responsive-4by3">
                <div id="oahVideoIframe" />
              </div>
            </a>
          </div>
          <div id="videoModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="videoModalTitle" aria-hidden="true">
            <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 id="videoModalTitle" class="modal-title" />
                  <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                      <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                    </svg>
                  </button>
                </div>
                <div class="modal-body p-2 px-sm-4 pt-0 pb-4">
                  <div style="padding:52% 0 0 0;position:relative;">
                    <iframe :src="$t('AcademyPage.VideoSection.WhatIsOAH.Video')" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="$t('AcademyPage.VideoSection.WhatIsOAH.Title')" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="container space-top-1 space-bottom-3 bg-img-hero bg-bottom-contain" :style="{ backgroundImage: `url(${require(`@/assets/img/home/api-connect-world-2.png`)})`}">
      <div class="w-md-65 mx-auto text-center z-index-2 mt-6">
        <span class="h1 font-size-5" v-html="$t('OW01HomePage.HeroSection.Title')" />
        <p class="lead mt-3 mb-6">{{ $t('OW01HomePage.HeroSection.Description') }}</p>
        <!-- Search -->
        <div class="input-group w-lg-80 mx-auto my-5">
          <input v-model="searchQuery" :placeholder="$t('OW01HomePage.HeroSection.SearchAPI')" type="text" class="form-control" required @keypress.enter="search">
          <div class="input-group-append">
            <button class="btn btn-block btn-primary" @click="search"><i class="far fa-search mr-1" /> {{ $t('OW01HomePage.HeroSection.Search') }}</button>
          </div>
        </div>
        <!-- End Search-->
        <span>{{ $t('OW01HomePage.HeroSection.MoreAPI') }}</span> <a class="font-weight-bold text-primary link-underline" href="https://hub.openapihub.com/api-catalogue/all" target="_blank">{{ $t('OW01HomePage.HeroSection.Explore') }} <i class="far fa-arrow-right ml-1" /></a>
      </div>
    </div>

    <div class="container">
      <div class="row mb-6 mt-n5">
        <div class="col-md-6 mb-6">
          <div class="card h-100">
            <div class="card-body text-center">
              <figure class="avatar max-w-6rem w-100 mb-2">
                <img class="img-fluid" :src="require(`@/assets/svg/icons/icon-7.svg`)">
              </figure>
              <h1 class="font-size-3">15,000+</h1>
              <p class="mb-0">{{ $t('OW01HomePage.HeroSection.Stat1') }}</p>
            </div>
            <div class="card-footer text-center border-top-0 pt-0">
              <a class="btn btn-primary btn-sm" href="https://hub.openapihub.com/login" target="_blank">{{ $t('OW01HomePage.HeroSection.Stat1Button') }}</a>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-6">
          <div class="card h-100">
            <div class="card-body text-center">
              <figure class="avatar max-w-6rem w-100 mb-2">
                <img class="img-fluid" :src="require(`@/assets/svg/icons/icon-19.svg`)">
              </figure>
              <h1 class="font-size-3">2,500+</h1>
              <p class="mb-0">{{ $t('OW01HomePage.HeroSection.Stat2') }}</p>
            </div>
            <div class="card-footer text-center border-top-0 pt-0">
              <router-link class="btn btn-primary btn-sm" :to="{ path: `/${$route.params.lang}/api-provider`}">{{ $t('OW01HomePage.HeroSection.Stat2Button') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container space-bottom-2">
      <div class="card z-index-2 mx-auto bg-transparent shadow-none">
        <div class="text-center mb-7">
          <span class="h3" v-html="$t('OW01HomePage.HeroSection.TrustedBy')" />
        </div>
        <Slick ref="CommunityProvidersCarousel" :options="slickOptions" class="align-items-center">
          <div v-for="(item, index) in providers" :key="index" class="js-slide">
            <img class="max-w-19rem mx-auto" :src="require(`@/assets/img/api-providers/${item.image}`)">
          </div>
        </Slick>
      </div>
    </div>

    <!-- <div class="card z-index-2">
        <div class="card-body space-1">
          <div class="row">
            <div v-for="(item, index) in stats" :key="index" class="col-md-4 mb-3 mb-md-0">
              <div class="text-center">
                <span class="d-block font-size-4 font-size-md-down-3 text-dark font-weight-bold">{{ item.number }}</span>
                <p style="color:#677788">{{ $t(item.description) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
  </div>

</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import Slick from 'vue-slick'

export default {
  name: 'Hero',
  components: {
    Slick
  },
  data() {
    return {
      searchQuery: '',
      typedKeywords: [],
      providers: [
        {
          image: 'fiberconnect-logo.svg'
        },
        {
          image: 'taptab-logo.svg'
        },
        {
          image: 'stemedia-logo.svg'
        },
        {
          image: 'openimagelab-logo.svg'
        },
        {
          image: 'atchative-logo.svg'
        },
        {
          image: 'benovelty-logo.svg'
        }
      ],
      stats: [
        {
          number: '3698+',
          description: 'OW01HomePage.HeroSection.Stat1'
        },
        {
          number: '2021+',
          description: 'OW01HomePage.HeroSection.Stat2'
        },
        {
          number: '11265+',
          description: 'OW01HomePage.HeroSection.Stat3'
        }
      ],
      slickOptions: {
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        prevArrow: '<span class="fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-n3 d-none" />',
        nextArrow: '<span class="fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-n3 d-none" />',
        responsive: [{
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 992,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 576,
          settings: {
            slidesToShow: 2
          }
        }]
      }
    }
  },
  watch: {
    '$i18n.locale'() {
      this.setKeywords()
    }
  },
  created() {
    this.setKeywords()
  },
  beforeUpdate() {
    if (this.$refs.CommunityProvidersCarousel) {
      this.$refs.CommunityProvidersCarousel.destroy()
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.CommunityProvidersCarousel) {
        this.$refs.CommunityProvidersCarousel.create(this.slickOptions)
      }
    })
  },
  mounted() {
    $('#videoModal').on('hidden.bs.modal', function(e) {
      $('#videoModal iframe').attr('src', $('#videoModal iframe').attr('src'))
    })
  },
  methods: {
    search() {
      const encodedQuery = encodeURIComponent(this.searchQuery)
      const url = `https://hub.openapihub.com/api-catalogue/all?key=${encodedQuery}`
      window.open(url, '_blank')
      this.clearInput()
    },
    clearInput() {
      this.searchQuery = ''
    },
    setKeywords() {
      this.typedKeywords = [
        this.$t('OW01HomePage.HeroSection.Wording1'),
        this.$t('OW01HomePage.HeroSection.Wording2'),
        this.$t('OW01HomePage.HeroSection.Wording3')
      ]
    },
    scrollToId(id) {
      document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~slick-carousel/slick/slick.css';

.modal-header {
  border-bottom: 0;
}

.text-body {
  height: 160px;
  padding: 10px 0;
}

.bg-bottom-contain {
  background-position: bottom;
  background-size: contain;
}

@media (max-width: 768px) {
  .font-size-5 {
    font-size: 2.5rem;
  }
}

@media (max-width: 576px) {
  .font-size-5 {
    font-size: 2rem;
  }
  .font-size-4 {
    font-size: 2.25rem;
  }
  .font-size-2 {
    font-size: 1.125rem;
  }
  .text-body {
  height: 110px;
  }
}

@media (max-width: 317px) {
  .text-body {
  height: 150px;
  }
}
</style>
